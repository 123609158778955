import React from "react"
const PubCard = ({ pubimg, title, authors, doi }) => (
  <div className="pubcard">
    <span className="image fit">
      <img src={pubimg} alt="" />
    </span>
    <h4>{title}</h4>
    <a href={doi} target="_blank" className="button small">
      DOI
    </a>
  </div>
)

export default PubCard
