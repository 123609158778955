import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import PubCard from "../components/pubcard.js"

import heatmap from "../assets/images/heatmap.png"
import park2015 from "../assets/images/pubcards/park2015.png"
import rokhzan2019 from "../assets/images/pubcards/rokhzan2019.png"
import faiz2017 from "../assets/images/pubcards/faiz2017.jpg"
import yoshie2018 from "../assets/images/pubcards/yoshie2018.jpg"
import chen2018 from "../assets/images/pubcards/chen2018.jpg"
import yoshie2019 from "../assets/images/pubcards/yoshie2019.png"
import redes2019 from "../assets/images/pubcards/redes2019.jpg"
import lu2020 from "../assets/images/pubcards/lu2020.jpg"
import thompson2020 from "../assets/images/pubcards/thompson2020.png"

const LeftSidebar = props => (
  <Layout>
    <Helmet>
      <title>Applications | Live Cell Technologies Canada</title>
      <meta name="description" content="Applications" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Applications</h2>
          <p>
            Our technology can applied to virtually any physiological screen or
            disease with a mechanical component.
          </p>
        </header>

        <section id="content">
          <img src={heatmap} alt="" />

          <p>
            The value of Contractile Force Screening is clear: identifying
            therapeutic leads while eliminating false positives early in the
            discovery process, and identifying cardiac muscle dysfunction that
            may cause drug recall, leads to more economical, faster, and safer
            pharmaceutical development. The applications of our technology do
            not end there, however. From developing better cosmetic products to
            understanding diverse diseases, and from investigating single cell
            mechanics to monolayers and collective cell migration, we want to
            help you unlock the power behind cell forces, and look forward to
            working with you to make your application possible.
          </p>
          <p>
            The science behind CFS is clear, and you can see for yourself in the
            numerous publications that have used our platform.
          </p>
          <h3>Publications</h3>
          <div className="box alt">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-4 col-6-xsmall">
                <PubCard
                  pubimg={park2015}
                  title="High-throughput screening for modulators of cellular contractile force."
                  authors="Park CY, Zhou EH, Tambe D, Chen B, Lavoie T, Dowell M, Simeonov A, Maloney DJ, Marinkovic A, Tschumperlin DJ, Burger S, Frykenberg M, Butler JP, Stamer WD, Johnson M, Solway J, Fredberg JJ, Krishnan R."
                  doi="https://doi.org/10.1039/c5ib00054h"
                />
              </div>
              <div className="col-4 col-6-xsmall">
                <PubCard
                  pubimg={faiz2017}
                  title="Latrophilin receptors: novel bronchodilator targets in asthma."
                  authors="Faiz A, Donovan C, Nieuwenhuis MAE, van den Berge M, Postma DS, Yao S, Park CY, Hirsch R, Fredberg J, Halayko AJ, Rempel KL, Ward JPT, Lee T, Bosse Y, Nickle DC, Obeidat M, Black JL, Oliver BG,
                Krishnan R, McParland B, Bourke JE, Burgess JK."
                  doi="http://doi.org/10.1136/thoraxjnl-2015-207236"
                />
              </div>
              <div className="col-4 col-6-xsmall">
                <PubCard
                  pubimg={yoshie2018}
                  title="Traction force screening enabled by compliant PDMS
                elastomers."
                  authors="Yoshie H, Koushki N, Kaviani R, Tabatabaei SM, Rajendran K, Dang Q, Husain A, Yao S, Li C, Sullivan J, Saint-Geniez M, Krishnan R, Ehrlicher AJ."
                  doi="https://doi.org/10.1016/j.bpj.2018.02.045"
                />
              </div>
              <div className="col-4 col-6-xsmall">
                <PubCard
                  pubimg={chen2018}
                  title="Retinoic Acid Signaling is Essential for Airway Smooth Muscle Homeostasis."
                  authors="Chen F, Shao FZ, Hinds A, Yao S, Ram-Mohan S, Timothy Norman, Krishnan R, and Fine A.  JCI Insight, in press, 2018."
                  doi="https://doi.org/10.1172%2Fjci.insight.120398"
                />
              </div>
              <div className="col-4 col-6-xsmall">
                <PubCard
                  pubimg={rokhzan2019}
                  title="Multiplexed, high-throughput measurements of cell contraction and endothelial barrier function."
                  authors="Rokhzan R, Ghosh CC, Schaible N, Notbohm J, Yoshie H, Ehrlicher AJ, Higgins SJ, Zhang R, Haller H, Hardin CC, David S, Parikh SM, Krishnan R."
                  doi="https://doi.org/10.1038/s41374-018-0136-2"
                />
              </div>
              <div className="col-4 col-6-xsmall">
                <PubCard
                  pubimg={yoshie2019}
                  title="High Throughput Traction Force Microscopy Using PDMS Reveals Dose-Dependent Effects of Transforming Growth Factor-β on the Epithelial-to-Mesenchymal Transition."
                  authors="Yoshie H, Koushki N, Molter C, Siegel PM, Krishnan R, Ehrlicher AJ."
                  doi="https://doi.org/10.3791/59364"
                />
              </div>
              <div className="col-4 col-6-xsmall">
                <PubCard
                  pubimg={redes2019}
                  title="Aspergillus fumigatus secreted alkaline protease 1 (Alp1) mediates airways hyper-responsiveness in severe asthma."
                  authors="Redes JL, Basu T, RamMohan S, Ghosh CC, Chan EC, Se AC, Zhao M, Krishnan R, Rosenberg HF, Druey KM."
                  doi="https://pubmed.ncbi.nlm.nih.gov/31603851"
                />
              </div>
              <div className="col-4 col-6-xsmall">
                <PubCard
                  pubimg={lu2020}
                  title="Inhibiting Airway Smooth Muscle Contraction Using Pitavastatin: A Role for the Mevalonate Pathway in Regulating Cytoskeletal Proteins."
                  authors="Lu R, Zeki AA, Ram-Mohan S, Nguyen N, Bai Y, Chmiel K, Ai X, Krishnan R, and Ghosh CC."
                  doi="https://doi.org/10.3389%2Ffphar.2020.00469"
                />
              </div>
              <div className="col-4 col-6-xsmall">
                <PubCard
                  pubimg={thompson2020}
                  title="Cytokine-induced molecular responses in airway smooth muscle cells inform genome-wide association studies of asthma."
                  authors="Thompson E, Dang Q, Mitchell-Handley B, Rajendran K, Ram-Mohan S, Solway J, Ober C, Krishnan R."
                  doi="https://doi.org/10.1186/s13073-020-00759-w"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

export default LeftSidebar
